@import "@/styles/variables";
@import "@/styles/mixins";

.gallery {
  max-width: 1330px;
  width: 100%;
  padding: 0 24px;

  @include for-screen-from-1200 {
    margin: 0 auto;
  }

  &_header {
    padding: 0 16px;
    margin: 0 auto 24px;

    h2 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;

      @include for-screen-from-1024 {
        font-size: 32px;
        line-height: 36px;
      }

      @include for-screen-from-1200 {
        font-size: 32px;
        line-height: 37px;
      }
    }
  }

  &_citiesBox {
    width: 100%;
    max-width: 1330px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.cityBox {
  height: 122px;
  min-width: auto;
  width: 33%;
  padding: 5px;
  text-align: center;
  color: $color-white;

  @include for-screen-from-768 {
    width: 25%;
    height: 171px;
    min-width: 138px;
  }

  @include for-screen-from-1024 {
    width: 20%;
    min-width: 138px;
    height: 288px;
  }

  &_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s;
    background-size: cover;
    position: relative;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      .cityBox_hoverText {
        transform: scale(1);
      }

      .cityBox_text {
        transform: scale(0);
      }

      .cityBox_hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &_text {
    position: absolute;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    transform: scale(1);
    z-index: 200;
    color: $color-white;
    background-color: rgba(0, 0, 0, .5);
    bottom: 4px;
    left: 4px;
    right: 4px;
    min-height: 22px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;

    @include for-screen-from-768 {
      bottom: 5px;
      left: 7px;
      right: 7px;
      width: auto;
      min-height: 30px;
      font-size: 14px;
    }

    @include for-screen-from-1024 {
      min-height: 45px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &_hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }

  &_hoverText {
    position: absolute;
    z-index: 11;
    transition: 0.3s;
    transform: scale(0);
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;

    span {
      display: block;
      width: auto;
      padding: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;

      @include for-screen-from-768 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
      }

      @include for-screen-from-1024 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

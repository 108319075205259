@import '@/styles/variables.scss';

.loader {
  margin: auto;

  &_item {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;

    &:nth-of-type(2n) .loader_itemBubble {
      animation-delay: -0.3s;
    }

    &:nth-of-type(3n) .loader_itemBubble {
      animation-delay: 0s;
    }
  }

  &_itemBubble {
    border-radius: 50%;
    background-color: $color-green-main;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    animation: bubble 1.2s -0.6s infinite ease-out;
  }
}

@keyframes bubble {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@-webkit-keyframes bubble {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

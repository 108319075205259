@import "@/styles/variables";
@import "@/styles/mixins";

.homeIntroSection {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto 30px;
  height: 100%;
  max-width: 1330px;
  padding: 0 $spacing-3;
  flex-direction: column;

  @include for-screen-from-568 {
    margin-bottom: 0;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &_search {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include for-screen-from-1024 {
      margin-top: 80px;
      width: 50%;
    }
  }

  &_wrapper {
    margin-bottom: 48px;

    @include for-screen-from-1200 {
      margin-bottom: 54px;
    }

    h1 {
      position: static;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0 0 10px;
      font-weight: 500;
      font-size: $font-size-xxxl;
      color: $color-black;

      @include for-screen-from-1200 {
        font-size: $font-size-xxxxl;
      }
    }

    p {
      color: $color-grey2;
      font-size: 16px;
      line-height: 24px;
      height: 24px;
      margin-top: 6px;

      @include for-screen-from-768 {
        font-size: 20px;
        line-height: 28px;
      }

      @include for-screen-from-1024 {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  &_banner {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include for-screen-from-568 {
      display: flex;
      height: 250px;
      width: 270px;
    }

    @include for-screen-from-1024 {
      display: flex;
      height: 463px;
      width: 487px;
    }
  }
}



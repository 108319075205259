@import "@/styles/variables";
@import "@/styles/mixins";

.reviewsSearchPlace {
  display: flex;
  flex-direction: column;
  gap: $spacing-7;

  &_title {
    font-size: $font-size-xxxl;
    font-weight: 500;
    text-align: center;
  }

  &_listingTypes {
    display: grid;
    grid-template-columns: repeat(1, minmax(230px, 1fr));
    gap: $spacing-4;
  }

  &_searchBox {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}

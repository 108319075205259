@import '@/styles/variables';

.deleteListingModal {
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-6;
    padding: $spacing-7 $spacing-5;
  }

  &_iconWrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-6;
    border: 1px dashed red;
    border-radius: 50%;
  }

  &_icon {
    font-size: 82px;
    color: $color-red;
  }

  &_text {
    color: $color-gray-dark;
  }

  &_actions {
    display: flex;
    gap: $spacing-5;
  }

  &_actionButton {
    min-width: 100px;
    outline: unset;
  }
}

@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.listingMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &_message {
    min-height: 130px;
    width: 100%;
    padding: $spacing-4;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    font-size: $font-size-s;
    font-family: inherit;
    resize: none;

    &::placeholder {
      color: $color-gray-dark;
      font-size: $font-size-s;
    }
  }

  &_sendMessage {
    width: 160px;
  }
}

@import "@/styles/variables";
@import "@/styles/mixins";

.briefly {
  max-width: 1330px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 24px;

  &_header {
    width: 100%;
    margin: 0 auto 24px;

    h2 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;

      @include for-screen-from-1024 {
        font-size: 32px;
        line-height: 36px;
      }

      @include for-screen-from-1200 {
        font-size: 32px;
        line-height: 37px;
      }
    }
  }

  &_list {
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 714px;
    overflow-x: auto;
    overflow-y: hidden;

    @include for-screen-from-768 {
      max-width: unset;
    }

    @include for-screen-from-768 {
      gap: 50px;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 230px;
    min-width: 230px;
    padding: 24px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;

    @include for-screen-from-1024 {
      min-height: 280px;
      max-width: 323px;
      padding: 30px;
      width: 100%;
    }
  }
}

.item {
  &_image {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
    position: relative;

    img {
      object-fit: contain;
    }
  }

  &_title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    text-align: start;
    color: #333;
    margin-bottom: 12px;

    @include for-screen-from-1024 {
      font-size: 18px;
      line-height: 20px;
    }
  }

  &_description {
    font-size: 16px;
    text-align: start;
    color: #727272;
  }
}

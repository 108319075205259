@import "@/styles/variables.scss";

.listingMoveInDate {
  font-size: $font-size-xs;

  &_move {
    color: $color-green-main;
    font-weight: 500;
  }

  &_date {
    color: $color-gray-dark;
  }
}

.listingMoveInDate__isDetailed {
  font-size: $font-size-base;
  display: flex;
  flex-direction: column;

  .listingMoveInDate_move {
    color: $color-gray-dark;
    margin-bottom: $spacing-1;
    font-weight: 400;
  }

  .listingMoveInDate_date {
    color: $color-black;
  }
}

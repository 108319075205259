@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.content {
  margin-top: $spacing-7;
  flex: 0;
}

.title {
  margin-top: $spacing-1;
  font-size: $font-size-xl;
  font-weight: 500;
}


@include for-screen-from-768 {
  .title {
    margin-top: $spacing-4;
    font-size: $font-size-xxl;
    font-weight: 500;
  }
}

@include for-screen-from-1024 {
  .title {
    margin-top: $spacing-6;
    font-size: $font-size-xxxl;
  }

  [dir="rtl"] {
    .main {
      padding: 0 $spacing-6 $spacing-6 0;
    }
  }
}

@include for-screen-from-1200 {
  .main {
    padding-right: 0;
  }
}

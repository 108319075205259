@import '@/styles/variables';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background: rgba(0 0 0 / .5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-maximal;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
}

.modal {
  border-radius: $border-radius-base;
  background: #fff;
  border: 1px solid $color-gray;
  max-width: 600px;
  width: 100%;

  &[data-entering] {
    animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-zoom {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

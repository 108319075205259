@import "@/styles/variables";

.reviewsPage {
  &_title {
    font-size: $font-size-xxxl;
    font-weight: 500;
    margin-top: $spacing-6;
    text-align: center;
  }

  &_description {
    font-size: $font-size-xxl;
    color: $color-gray-dark;
    max-width: 790px;
    font-weight: 400;
    margin: $spacing-6 auto 0;
    text-align: center;
  }

  &_reviews {
    margin-top: $spacing-8;
  }

  &_topReviews {
    margin-top: $spacing-7;
    display: flex;
    justify-content: center;
  }

  &_searchPlace {
    margin-top: $spacing-9;
  }
}

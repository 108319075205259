@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.pageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.phoneAuthPage {
  max-width: 620px;
  width: 100%;
  margin: $spacing-7 auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $spacing-5;
}

.phoneAuthPage_footer {
  margin-top: auto;
}

@include for-screen-from-480 {
  .phoneAuthPage {
    margin: 240px auto 0;
  }
}

@include for-screen-from-1024 {
  .phoneAuthPage {
    margin: 100px auto 50px;
    padding: 0 $spacing-4;
  }
}


@import "@/styles/variables.scss";

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 30px;
  list-style: none;
  font-size: 16px;

    &_item {
      display: flex;
      align-items: center;
      color: $color-green-main;

      &:first-child {
        .breadcrumbItem_text {
          padding-left: 0;
        }
      }

      &:hover {
      text-decoration: underline;
      }

      &__disabled {
        color: $color-gray-dark;

        &:hover {
          text-decoration: none;
        }
      }
    }
}

.breadcrumbItem {
  &_text {
    line-height: 22px;
    padding: 0 $spacing-3;
  }
}

.home_icon {
  color: $color-green-main;
  font-size: 30px;
  line-height: 0;
}

.arrow_icon {
  color: $color-gray-dark;
  font-size: 14px;
  line-height: 0;
}

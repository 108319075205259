@import "variables";

// Media
@mixin for-screen-from-1200 {
  @media (min-width: 1200px) { @content; }
}

@mixin for-screen-from-1024 {
  @media (min-width: 1024px) { @content; }
}

@mixin for-screen-from-768 {
  @media (min-width: 768px) { @content; }
}

@mixin for-screen-from-568 {
  @media (min-width: 568px) { @content; }
}

@mixin for-screen-from-480 {
  @media (min-width: 480px) { @content; }
}

@mixin for-screen-from-360 {
  @media (min-width: 360px) { @content; }
}


// Utils
@mixin hide {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  transform: translateX(100%);
}

@mixin show {
  visibility: visible;
  left: unset;
  top: unset;
  right: unset;
  bottom: unset;
  transform: translateX(0);
}

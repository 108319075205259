@import "@/styles/variables";
@import "@/styles/mixins";

.menu_background {
  @include hide();

  z-index: $z-index-mobile-menu-background;
  opacity: 0;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.5);

  &__isActive {
    @include show();
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
}

.menu {
  @include hide();

  z-index: $z-index-mobile-menu;
  left: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  overflow-x: scroll;
  margin-left: auto;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  padding: 0 $spacing-1 $spacing-5;

  &::-webkit-scrollbar {
    display: none;
  }

  &__isActive {
    @include show();
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &_userButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-3;
    background-color: transparent;
    border-radius: $border-radius-base;
    cursor: pointer;
    border: none;
  }

  &_userName {
    display: none;
  }

  &_userInfoBlock {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    padding: $spacing-3 $spacing-4;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;

    &:hover {
      border-color: $color-gray-dark;
      background-color: $color-gray-light;
    }
  }

  &_arrowIcon {
    margin-left: auto;
    font-size: $font-size-s;
    color: $color-gray-dark;
  }
}

.upgradeAccount {
  margin-bottom: $spacing-3;
  min-height: 80px;

  &_avatar {
    position: relative;
  }

  &_avatarIcon {
    position: absolute;
    right: -$spacing-1;
    bottom: $spacing-1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black;
    border-radius: 50%;
    padding: $spacing-1;
  }

  &_avatarIconSvg {
    font-size: $font-size-xs;
    color: #fff;
  }

  &_title {
    font-size: $font-size-l;
    font-weight: 500;
  }

  &_description {
    font-size: $font-size-s;
    color: $color-gray-dark;
  }
}

.userVerifications {
  color: $color-gray-dark;

  &_list {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-6;
  }

  &_icon {
    color: $color-gray-dark;
    font-size: $font-size-l;

    &__isChecked {
      color: $color-green-main;
    }

    &__isPending {
      color: $color-orange;
    }

    &__isRejected,
    &__isError {
      color: $color-red;
    }
  }
}

.menu_icon {
  font-size: 38px;
  line-height: 0;
}

.logo_wrapper {
  margin: $spacing-4 auto $spacing-6;
}

.logo {
  display: inline-block;
  line-height: 0;
}

.close_menu {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 0.6;
  scale: 1;
  cursor: pointer;
  animation: normal;
  transition:
          scale 0.2s ease-in-out,
          opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    scale: 1.1;
  }
}

.login {
  margin-top: $spacing-5;
  margin-left: $spacing-4;
  width: 120px;
}

.nav {
  margin-top: $spacing-5;
  list-style: none;

  &_item {
    position: relative;
  }

  &_messageIndicator {
    &:first-child {
      margin-left: auto;
    }
  }

  &_item {
    position: relative;
  }

  &_messageIndicatorHelpdesk {
    position: absolute;
    top: -5px;
    left: -1px;
  }


  &_link {
    padding: $spacing-4 $spacing-3;
    display: flex;
    align-items: center;
    border-radius: $border-radius-base;
    gap: $spacing-3;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    border: none;
    background-color: unset;
    font-size: $font-size-base;
    width: 100%;

    &:hover {
      background-color: lighten($color-gray, 7%);
    }
  }

  &_logout {
    width: 100%;
  }

  &_icon {
    font-size: 22px;
    color: $color-gray-dark;
  }

  &_divider {
    height: 1px;
    margin: $spacing-4;
    background-color: $color-gray;
  }

  &_arrowIcon {
    margin-left: auto;
    font-size: $font-size-s;
    color: $color-gray-dark;
  }

  &_indicatorsArea {
    display: flex;
    flex-grow: 1;
    gap: $spacing-1;
  }

  &_completeBadge {
    margin-left: auto;
  }
}

@include for-screen-from-480 {
 .menu {
   width: 60%;
   max-width: 420px;
   padding: 0 $spacing-5 $spacing-5;

   &_userButton {
     padding: $spacing-3 $spacing-4;

     &:hover {
       background-color: $color-gray-light;
     }
   }

   &_userName {
     display: block;
     font-size: $font-size-base;
   }
 }

  .nav {
    &_link {
      gap: $spacing-4;
      padding: $spacing-4 $spacing-5;
    }
  }
}

@include for-screen-from-1200 {
  //.menu {
  //  &_userName {
  //    display: block;
  //  }
  //}
}


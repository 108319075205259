@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingGrid {
  min-height: 206px;
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

@import "@/styles/variables";
@import "@/styles/mixins";

.inThePress {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-screen-from-568 {
    align-items: flex-start;
  }

  @include for-screen-from-768 {
    padding: 50px 24px 0;
    margin: 0 auto 24px;
    max-width: 1330px;
    width: 100%;
  }

  &_header {
    width: 100%;

    h2 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;
      margin-bottom: 24px;

      @include for-screen-from-1024 {
        font-size: 32px;
        line-height: 36px;
      }

      @include for-screen-from-1200 {
        font-size: 32px;
        line-height: 37px;
      }
    }
  }

  &_list {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 30px;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 30px;
    max-width: 1330px;
  }

  &_listItem {
    display: flex;
    justify-content: flex-start;
    min-width: 110px;
    width: 100%;
    height: 40px;
    position: relative;
    object-fit: contain;

    &:last-child {
      display: none;
    }

    @include for-screen-from-1200 {
      &:last-child {
        display: flex;
      }
    }

    img {
      object-fit: contain;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  height: 54px;
  max-width: 327px;
  border: 1px solid $color-black;
  background-color: $color-black;
  border-radius: 8px;
  transition: all .2s linear;
  padding: 5px;
  user-select: none;
  font-size: 20px;
  line-height: 22px;
  margin: 0;
  min-width: 200px;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color-white;
    color: $color-black;
    text-decoration: none;
  }

  @include for-screen-from-1200 {
    max-width: 288px;
    width: 100%;
    height: 41px;
    margin: 24px 0;
  }
}

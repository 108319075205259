@import '@/styles/variables';

.imagesFormPart {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;

  &_imagesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: 140px;
    grid-gap: $spacing-2;
  }

  &_imageWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    overflow: hidden;
    cursor: pointer;
    transition: border-color $transition-time $transition-variant;

    &:hover {
      border-color: $color-green-main;
    }
  }

  &_image {
    object-fit: cover;
  }

  &_action {
    position: absolute;
    right: $spacing-1;
    top: $spacing-1;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $color-black;
    background-color: #fff;
    z-index: 3;
    font-size: 16px;
    cursor: pointer;
    transition:
            background-color 0.2s ease-in-out;
    transform-origin: left;

    &:hover {
      background-color: $color-black;
      color: $font-color-light;
    }
  }

  &_imageOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-l;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1.2px;
    z-index: 1;
  }

  &_imageDeleted {
    background-color: rgba($color-gray-dark, 0.5);
  }

  &_imagePrimary {
    background-color: rgba($color-green-main, 0.5);
  }
}

@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.userDocumentSelect {
  &_idTypesList {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
    list-style: none;
  }

  &_icon {
    font-size: $font-size-xxxl;
  }

  &_idTypeLink {
    display: flex;
    width: 100%;
    align-items: center;
    color: $color-grey3;
    gap: $spacing-4;
    padding: $spacing-4 $spacing-5;
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    background-color: transparent;
    font-size: $font-size-base;
    cursor: pointer;
    transition:
            background-color 0.2s ease-in-out,
            background-color 0.2s ease-in-out,
            color 0.2s ease-in-out;

    &[data-hovered] {
      background-color: $color-green-main;
      border-color: $color-green-main;
      color: $color-white;
    }

    &[data-disabled]{
      background-color: $color-gray;
      border-color: $color-gray;
      color: $color-white;
      cursor: not-allowed;
    }
  }

  &_loader {
    display: flex;
    height: 18px;
  }
}

@include for-screen-from-568 {
  .userDocumentSelect {
    &_idTypeLink {
      font-size: $font-size-xl;
    }

    &_icon {
      font-size: $font-size-xxxl;
    }
  }
}

@import "@/styles/variables";
@import "@/styles/mixins";

.topCities {
  max-width: 1330px;
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;

  &_header {
    max-height: 330px;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 auto 48px;

    h2 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;

      @include for-screen-from-1024 {
        font-size: 32px;
        line-height: 36px;
      }

      @include for-screen-from-1200 {
        font-size: 32px;
        line-height: 37px;
      }
    }
  }

  &_list {
    column-count: 2;
    column-gap: 10px;
    padding: 10px;

    @include for-screen-from-568 {
      column-count: 3;
    }

    @include for-screen-from-768 {
      column-count: 4;
    }

    @include for-screen-from-1024 {
      column-count: 6;
    }

    @include for-screen-from-1200 {
      column-count: 7;
    }
  }
  &_item {
    max-height: 60px;
    height: 40px;
    overflow-x: hidden;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    text-wrap: nowrap;

    @include for-screen-from-568 {
      font-size: 14px;
      line-height: 16px;
      text-align: start;
    }

    @include for-screen-from-1024 {
      column-count: 6;
    }

    @include for-screen-from-1200 {
      column-count: 7;
    }
  }
}

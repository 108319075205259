@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingGridItem {
  min-height: 110px;
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-white;
  padding: 30px 12px 12px;
  border-radius: 14px;
  border: 2px solid transparent;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 10px;
  color: $color-grey3;
  font-size: 16px;

  @include for-screen-from-1024 {
    max-width: 335px;
    min-height: 206px;
  }

  &:hover {
    border-color: $color-black;
    color: $color-black;

    .listingGridItemName {
      color: $color-black;
    }

    .listingGridItemImageWrapper{
      color: $color-black;
    }
  }

  &_choose {
    border-color: $color-black;

    .listingGridItemName {
      color: $color-black;
    }
  }

  &_imageWrapper {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 13px;

    img {
      object-fit: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    @include for-screen-from-1024 {
      width: 100px;
      height: 100px;
    }
  }

  &_name {
    text-transform: capitalize;
    color: $color-grey4;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    @include for-screen-from-1024 {
      font-size: 20px;
      line-height: 26px;
    }
  }
}



.embla__slide {
  --slide-height: 279px;
  --slide-size: 100%;
  --slide-spacing: 4px;
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  height: var(--slide-height);
  width: 100%;
  min-height: var(--slide-height);
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  min-height: var(--slide-height);
  object-fit: cover;
}

.embla__slide__img {
  user-select: none;
}
//
//.embla__lazyLoad {
//  position: relative;
//}
//
//.embla__lazyLoad__img {
//  opacity: 0;
//  transition: opacity 0.3s ease-in-out;
//}
//
//.embla__lazyLoad__hasLoaded .embla__lazyLoad__img {
//  opacity: 1;
//}
//
//.carousel_skeleton {
//  position: absolute;
//  opacity: 1;
//  z-index: 1;
//}
//
//.embla__lazyLoad__hasLoaded .carousel_skeleton {
//  opacity: 0;
//  transition: opacity 0.3s ease-in-out;
//}

@import '@/styles/variables';

.imagesDropZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-4;
  padding: $spacing-5 $spacing-3;
  min-height: 120px;
  border: 1px solid $color-grey4;
  border-radius: $border-radius-base;
  background-color: $color-gray-light;

  &[data-drop-target] {
    background: rgba($color-green-main, 0.1);
  }

  &_button {
    border-radius: $border-radius-base;
    padding: 8px 12px;
    border: $border-width-base solid $color-black;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    color: $font-color-dark;
    transition:
            color $transition-time $transition-variant,
            background-color $transition-time $transition-variant;

    &:hover {
      color: $font-color-light;
      background-color: $color-black;
    }
  }

  &_infoText {
    font-size: $font-size-s;
    color: $color-grey3;
    display: block;
  }
}

@import "@/styles/mixins.scss";

.banner {
  align-items: center;
  justify-content: flex-end;
  display: none;
  width: 100%;
  height: 100%;

  @include for-screen-from-768 {
    display: flex;
  }

  &__hidden {
    visibility: hidden;
  }

  &_phone {
    width: 150px;
    height: 210px;
    position: relative;

    img {
      object-fit: contain;
    }

    @include for-screen-from-1024 {
      width: 214px;
      height: 300px;
    }

    @include for-screen-from-1200 {
      width: 292px;
      height: 380px;
    }
  }

  &_appLinks {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &_rating {
    display: flex;
    position: relative;
  }

  &_ratingBackground {
    position: relative;
    width: 108px;
    height: 69.69px;

    @include for-screen-from-1024 {
      width: 154px;
      height: 99.38px;
    }

    @include for-screen-from-1200 {
      width: 192px;
      height: 123.9px;
    }
  }
}

.startContainer {
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 5px;
  align-items: center;
  justify-content: flex-start;
  width: 99px;
  height: 17px;

  @include for-screen-from-1200 {
    bottom: 30px;
    left: 10px;
  }

  &_item {
    color: $color-yellow;
    position: relative;

    svg {
      width: 9px;
      height: 9px;
    }

    @include for-screen-from-1024 {
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.marketingList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  &_link {
    width: 66px;
    height: 22px;
    position: relative;

    @include for-screen-from-1024 {
      width: 94px;
      height: 32px;
    }

    @include for-screen-from-1200 {
      width: 117px;
      height: 41px;
    }
  }
}

@import "@/styles/variables";

.notificationsIndicator {
  display: inline-flex;
  gap: $spacing-1;
  padding: $spacing-1 $spacing-3;
  border-radius: $border-radius-base;
  color: #fff;
  line-height: $font-size-s;

  &__isGreen {
    background-color: $color-green-main;
  }

  &__isBlue {
    background-color: $color-blue;
  }

  &__isOrange {
    background-color: $color-orange;
  }

  &_icon {
    font-size: $font-size-s;
    line-height: 0;
  }

  &_text {
    font-weight: 500;
    font-size: $font-size-xs;
  }
}

@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.addListingsPage {
  max-width: 800px;
  width: 100%;
  margin: $spacing-7 auto;
  padding: 0 $spacing-3;

  &_title {
    font-weight: 400;
    margin-bottom: $spacing-6;
    font-size: 26px;
    color: $color-black;
  }

  &_listingTypesImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }

  &_listingTypeGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    grid-template-rows: repeat(2, 200px);
    gap: $spacing-5;
  }

  &_listingTypesLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-6;
    padding: $spacing-3;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    box-shadow: $shadow;
    color: $color-grey4;
    transition: color $transition-time $transition-variant, border-color $transition-time $transition-variant;

    svg {
      height: 80px;
    }

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }

  &_listingTypeName {
    font-size: $font-size-l;
    text-align: center;
  }
}

@include for-screen-from-360 {
  .addListingsPage {
    &_listingTypesImageWrapper {
      width: 100px;
      height: 100px;
    }

    &_listingTypesLink {
      svg {
        height: 100px;
      }
    }
  }
}

@include for-screen-from-568 {
  .addListingsPage {
    &_title {
      font-size: $font-size-xxxl;
    }

    &_listingTypeName {
      font-size: $font-size-xxl;
      text-align: center;
    }
  }
}

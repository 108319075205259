@import "@/styles/variables";
@import "@/styles/mixins";

.createListingSection {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto 30px;
  height: 100%;
  max-width: 1330px;
  padding: 20px 24px;
  justify-content: center;

  @include for-screen-from-768 {
    padding-top: 0;
    padding-bottom: 0
  }

  &__hidden {
    display: none;
  }

  &_button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    border: 1px solid $color-black;
    background-color: $color-black;
    border-radius: 8px;
    transition: all .2s linear;
    padding: 5px;
    user-select: none;
    width: 114px;
    min-width: auto;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
    margin-top: -60px;
    white-space: nowrap;
    overflow: hidden;

    @include for-screen-from-480 {
      width: 160px;
      height: 45px;
      font-size: 16px;
      line-height: 19px;
    }

    @include for-screen-from-768 {
      width: 230px;
      height: 45px;
      font-size: 16px;
      line-height: 19px;
    }

    @include for-screen-from-1024 {
      font-size: 20px;
      line-height: 22px;
      margin: 0;
      height: 54px;
      width: 327px;
    }

    &::before {
      content: '+';
      display: inline-flex;
      margin: -5px 12px 0;
      font-size: 23px;
      line-height: 23px;
    }
  }

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1330px;

    .createBanner {
      width: calc(50% - 10px);
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.listPlace {
        height: 160px;

        img {
          border-radius: 0 80px;
          object-fit: cover;
          background-repeat: no-repeat;
        }

        @include for-screen-from-568 {
          height: 200px;

          img {
            border-radius: 0 80px;
          }
        }

        @include for-screen-from-1024 {
          height: 220px;

          img {
            border-radius: 0 100px;
          }
        }
      }

      &.findPlace {
        height: 160px;

        img {
          object-fit: cover;
          border-radius: 80px 0;
          background-repeat: no-repeat;
        }

        @include for-screen-from-568 {
          height: 200px;

          img {
            border-radius: 100px 0;
          }
        }

        @include for-screen-from-1024 {
          height: 220px;
        }
      }

      @include for-screen-from-1024 {
        width: 45%;
        height: 200px;
      }

      .landing-section__button {
        &::before {
          content: '+';
          display: inline-flex;
          margin: -5px 12px 0;
          font-size: 23px;
          line-height: 23px;
        }
      }
    }
  }
}

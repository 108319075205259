@import "@/styles/variables.scss";
@import '@/styles/mixins.scss';

.embla {
  --slide-height: 279px;
  --slide-size: 100%;
  --slide-spacing: 4px;

  max-width: 48rem;
  position: relative;

  &_isNew {
    position: absolute;
    top: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-3 $spacing-5;
    z-index: 1;
    background-color: $color-green;
    color: #fff;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }

  &_relativeDate {
    position: absolute;
    bottom: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-1 $spacing-3;
    z-index: 1;
    font-size: $font-size-xs;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &_bookmark {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 0;
    scale: 1;
    transition:
            scale 0.1s ease-in-out;
    animation: normal;
    font-size: 26px;
    line-height: 0;
    padding: 7px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      scale: 1.15;
    }
  }

  &_bookmarkIcon {
    stroke-width: 18;
    stroke: #fff;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s ease-in-out;

    &__isBookmarked {
      color: #fff;
    }
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  height: var(--slide-height);
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 372px;
  object-fit: cover;
}

.embla__slide__img {
  user-select: none;
}


@include for-screen-from-568 {
  .embla {
    --slide-size: 50%;
  }
}

@include for-screen-from-768 {
  .embla {
    max-width: 48rem;
    position: relative;
    --slide-height: 279px;
    --slide-size: 488px;
  }
}

@include for-screen-from-1024 {
  .embla__buttons {
    opacity: 0;
  }

  .embla {
    &:hover .embla__buttons {
      opacity: 1;
    }
  }
}

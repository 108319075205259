@import "@/styles/variables";
@import "@/styles/mixins";

.reviewCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-4;
  border-radius: $border-radius-base;
  border: 1px solid $color-gray-light;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,.15);

  &__horizontal {
    flex-direction: column;
    gap: $spacing-7;
    align-items: flex-start;
    padding: $spacing-6 $spacing-5;

    .reviewCard_title {
      min-height: unset;
    }

    .reviewCard_top {
      min-width: 170px;
    }
  }

  &_top {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
  }

  &_userLocation {
    color: $color-grey2;
  }

  &_rating {
    display: flex;
    align-items: center;
    gap: 1px;
  }

  &_star {
    color: $color-yellow;
    position: relative;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  &_title {
    font-weight: 500;
    min-height: 38px;
  }

  &_date {
    color: $color-grey2;
    font-size: $font-size-s;
  }

  &_description {
    position: relative;
    height: 82px;
    overflow: hidden;
    line-height: 20px;
    font-size: $font-size-s;

    &__full {
      height: auto;
    }

    &:not(&__full):after {
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 6;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), #fff 40%);
    }
  }
}

@include for-screen-from-568 {
  .reviewCard {
    &__horizontal {
      flex-direction: row;
    }
  }
}

@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.listingMessageModal {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-6;
  width: 100%;

  &_title {
    font-weight: 500;
    font-size: $font-size-xxl;
  }

  &_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
  }

  &_image {
    position: relative;
    width: 100%;
    height: 210px;
  }

  &_details {
    display: flex;
    flex-direction: column;
    padding: $spacing-3;
    gap: $spacing-3;
  }

  &_listingTitle {
    font-size: $font-size-l;
    font-weight: 500;
  }
}

@include for-screen-from-480 {
  .listingMessageModal {
    &_card {
      flex-direction: row;
    }

    &_image {
      width: 130px;
      height: 90px;
    }
  }
}

@include for-screen-from-768 {
  .listingMessageModal {
    width: 600px;
  }
}

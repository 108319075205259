@import "@/styles/variables";
@import "@/styles/mixins";

.option_icon {
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing-2;
  background-color: $color-gray;
  color: $color-black;
  font-size: 14px;
  border-radius: $border-radius-base;
  transition:
    color $transition-time $transition-variant,
    background-color $transition-time $transition-variant;
}

.option_link {
  display: flex;
  align-items: center;
  width: 100%;
}

@include for-screen-from-480 {
  .option_icon {
    min-width: 42px;
    min-height: 42px;
    font-size: 16px;
  }
}

@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.idCheckPage {
  max-width: 500px;
  margin: $spacing-7 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-6;

  &_box {
    width: 100%;
    padding: $spacing-5;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_chooseType {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
  }

  &_title {
    font-size: $font-size-xxl;
    font-weight: 400;
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
    color: $color-grey2;
  }
}

@include for-screen-from-568 {
  .idCheckPage {
    &_box {
      padding: $spacing-6 $spacing-7;
    }
  }
}

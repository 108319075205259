@import "@/styles/variables.scss";

.listingRate {
  display: flex;
  align-items: center;
  gap: $spacing-4;

  &_value {
    display: flex;
    align-items: center;
    gap: $spacing-1;
    font-size: $font-size-l;
    font-weight: 500;
  }
}


.listingRate__isDetailed {
  gap: $spacing-3;

  .listingRate_value {
    font-size: $font-size-base;
    font-weight: 400;
  }
}

@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.contentInfoLayout {
  max-width: 1090px;
  margin: 0 auto;
  padding: $spacing-6 $spacing-4;

  &_title {
    margin-bottom: $spacing-7;
    font-size: $font-size-xxxl;
    font-weight: 400;
    text-align: center;
  }

  &_subtitle {
    margin-bottom: $spacing-5;
    font-size: $font-size-xxl;
    font-weight: 500;
  }
}

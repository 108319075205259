@import "@/styles/variables";
@import "@/styles/mixins";

.reviewsTabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &[data-orientation=horizontal] {
    flex-direction: column;
  }

  &_tabList {
    position: relative;
    display: flex;
    gap: $spacing-4;
    overflow-y: scroll;
    margin: unset;
    padding-bottom: $spacing-4;

    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 100%;
      content: "";
      background: linear-gradient(90deg, hsla(0, 0%, 100%, .5), #fff 40%);
    }
  }

  &_tab {
    padding: $spacing-3 $spacing-4;
    position: relative;
    transition: color 200ms;
    forced-color-adjust: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    text-wrap: nowrap;

    &[data-hovered],
    &[data-focused] {
      color: $color-green-main;
    }

    &[data-selected] {
      border-color: $color-green-main;
      cursor: default;
      color: $color-black;
      //color: $color-green-main;
    }

    &[data-disabled] {
      cursor: default;
      color: $color-grey2;
      &[data-selected] {
        border-color: $color-grey2;
      }
    }

    &[data-focus-visible]:after {
      content: '';
      position: absolute;
      inset: 4px;
      border-radius: 4px;
      border: 2px solid $color-green-main;
    }
  }

  &_tabPanel {
    margin-top: 4px;
    padding: $spacing-3 0;
    border-radius: 4px;

    &[data-focus-visible] {
      outline: 2px solid $color-green-main;
    }
  }
}

@include for-screen-from-768 {
  .reviewsTabs {
    &_tabList {
      margin: 0 auto;

      &:after {
        display: none;
      }
    }
  }
}

@import "@/styles/variables";

.headerLoginedLinks {
  display: flex;
  gap: $spacing-4;
  list-style: none;

  &_item {
    position: relative;
  }

  &_messageIndicator {
    position: absolute;
  }

  &_messageIndicatorHelpdesk {
    top: -8px;
    right: -2px;
  }

  &_messageIndicatorRight {
    top: -8px;
    right: -9px;
  }

  &_messageIndicatorLeft {
    top: -8px;
    left: -9px;
  }
}

@import '@/styles/variables';

.container {
  display: flex;
  gap: 12px;
}

.link {
  display: block;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border: 1px solid $color-gray;
  border-radius: 50%;
  line-height: 0;
  transition: border-color $transition-time $transition-variant;

  &:hover {
    border-color: $color-black;
  }
}

.google_icon {
  padding-left: 10px;
  padding-right: 4px;
}

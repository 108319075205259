@import "@/styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.contentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  width: 200%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
  z-index: 4000;
}

.shiftLeft .content {
  transform: translateX(-100%);
}

.inputGroup,
.replacementContent {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.replacementContent {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  flex-direction: column;

  &Button {
    font-size: 20px;

    svg {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }


    &:hover {
      color: $color-black;
      background-color: transparent;
    }
  }
}

.shiftLeft .replacementContent {
  opacity: 1;
}

.shiftLeft .inputGroup {
  opacity: 0;
}

.hidden {
  display: none;
}

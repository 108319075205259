@import "@/styles/variables";
@import "@/styles/mixins";

$slide-spacing: $spacing-4;
$slide-size: calc(100% / 3);
$slide-height: auto;

.embla {
  position: relative;
  margin: auto 0;
}

.embla__viewport {
  overflow: hidden;
  padding: $spacing-2;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc($slide-spacing * -1);
}

.embla__buttons {
  display: none;
}

.embla__slide {
  flex: 0 0 80%;
  min-width: 0;
  padding-left: $slide-spacing;
  height: $slide-height;
  display: flex;

}

@include for-screen-from-768 {
  .embla {
    position: relative;
    margin: 0 $spacing-8;
  }

  .embla__slide {
    flex: 0 0 calc(100% / 2);
  }

  .embla__buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}

@include for-screen-from-1024 {
  .embla {
    position: relative;
  }

  .embla__slide {
    flex: 0 0 calc(100% / 3);
  }
}

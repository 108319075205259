@import "@/styles/variables.scss";

.userDocumentPage {
  max-width: 500px;
  margin: $spacing-7 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-6;

  &_box {
    width: 100%;
    padding: $spacing-6 $spacing-7;
    border: 1px solid $color-gray-light;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_uploadDocument {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
  }

  &_title {
    font-size: $font-size-xxl;
    font-weight: 400;
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
    color: $color-grey2;
  }

  &_requirementsList {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
    list-style: none;
  }

  &_requirementItem {
    display: flex;
    gap: $spacing-2;
  }

  &_requirementIcon {
    color: $color-red;
    line-height: 0;
    font-size: $font-size-xs;
  }

  &_uploadDocument {
    margin-top: $spacing-3;
  }
}

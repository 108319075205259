@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";


.homePageFooter {
  &Wrapper {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: $color-black;
    background: $color-black;
    font-weight: 200;
  }

  &Section {
    color: $color-white;
    padding: 32px 27px 26px;
    max-width: 1258px;
    margin: 0 auto;
    width: 100%;

    &_mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      max-width: 320px;
      width: 100%;

      @include for-screen-from-568 {
        display: none;
      }
    }

    &_desktop {
      display: none;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
      width: 100%;

      @include for-screen-from-568 {
        display: grid;
      }
    }

    &_column {
      display: flex;
      flex: content;
    }
  }

  &ColumnsWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &Apps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &AppsRow {
    display: flex;
    gap: 20px;

    &_text {
      font-size: 16px;
      margin-bottom: 24px;
    }

    &_rating {
      display: flex;
      width: 87px;
      height: 17px;
      align-items: center;
      justify-content: space-between;

      @include for-screen-from-568 {
        width: 99px;
      }
    }
  }
}

.mediaLinks {
  display: flex;
  gap: 10px;
}

.footerLink {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 16px;
  list-style-type: none;
}

.socialItem {
  background-color: $color-green;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startContainerItem {
    color: $color-yellow;
    width: 14px;
    height: 14px;
    position: relative;

    @include for-screen-from-768 {
      width: 17px;
      height: 17px;
    }
}

.marketingList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;

  @include for-screen-from-568 {
    flex-direction: row;
  }

  &_link {
    width: 132px;
    height: 45px;
    position: relative;

    img {
      border-radius: 11px;
      object-fit: contain;
    }

    @include for-screen-from-568 {
      width: 66px;
      height: 22px;
    }

    @include for-screen-from-1024 {
      width: 94px;
      height: 32px;
    }

    @include for-screen-from-1200 {
      width: 117px;
      height: 41px;
    }
  }
}

.copyRight {
  font-weight: 200;
}
